import { useState, useEffect } from 'react';
import { ConsolePage } from './pages/ConsolePage';
import './App.scss';

function App() {
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode === 'true';
  });

  useEffect(() => {
    localStorage.setItem('darkMode', darkMode.toString());
  }, [darkMode]);

  const handleToggle = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div data-component="App" className={darkMode ? 'dark-mode' : ''}>
      <ConsolePage darkMode={darkMode} onToggleDarkMode={handleToggle} />
    </div>
  );
}

export default App;
